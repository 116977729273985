import { List, ListSubheader, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const CustomListSubHeader = styled(ListSubheader)(({ theme }) =>
  theme.unstable_sx({
    textTransform: 'uppercase',
    lineHeight: 'normal',
    pl: 2,
    pt: 2,
    pb: 1,
  })
);

export interface MainMenuSectionProps {
  title: string;
}

export const MainMenuSection = ({ children, title }: PropsWithChildren<MainMenuSectionProps>) => (
  <>
    <List
      subheader={<CustomListSubHeader>{title}</CustomListSubHeader>}
      sx={{
        '&.active': {
          color: 'primary.main',
        },
      }}
    >
      {children}
    </List>
  </>
);
