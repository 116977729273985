import { ProjectType } from '@dbel/shared/types';
import { EmissionIconSvg, RoomAcousticIconSvg } from '@dbel/react-commons/assets';
import { ReactNode } from 'react';
import ListIcon from '@mui/icons-material/List';
import HvacOutlinedIcon from '@mui/icons-material/HvacOutlined';
import { PERMISSIONS_WRITE_ACCOUNT } from './components/AuthGuard';
import { WysiwygSharp } from '@mui/icons-material';
import { AccountAppRunConfiguration } from '@dbel/dbel-service-app-run/types';

// TODO: This should be dynamic as well
/**
 * {@param restrictions} add roles for certain restriction {@link AuthGuard} PERMISSIONS_WRITE_DBEL_ACCOUNTS = 'write:dbel-accounts';
 */
type AppIntent = {
  name: string;
  route: string;
  icon: ReactNode;
  restrictions?: string[];
};

export type App = {
  name: string;
  product: string;
  projectType: [ProjectType];
  icon: ReactNode;
  path: string;
  intents: AppIntent[];
};

export const AppRegister: Record<ProjectType, App> = {
  [ProjectType.ARCHITECT]: {
    name: 'architect',
    product: 'ARCHITECT',
    projectType: [ProjectType.ARCHITECT],
    icon: <EmissionIconSvg sx={{ height: '100%', width: '100%' }} />,
    path: 'architect',
    intents: [{ name: 'projects', route: 'app/architect/projects', icon: <ListIcon /> }],
  },
  [ProjectType.ROOM_ACOUSTICS]: {
    name: 'roomacousticcalculator',
    product: 'RAC',
    projectType: [ProjectType.ROOM_ACOUSTICS],
    icon: <RoomAcousticIconSvg sx={{ height: '100%', width: '100%' }} />,
    path: 'roomacoustic',
    intents: [{ name: 'projects', route: 'app/roomacoustic/projects', icon: <ListIcon /> }],
  },
  [ProjectType.STIEBEL]: {
    name: 'hvac',
    product: 'HVAC',
    projectType: [ProjectType.STIEBEL],
    icon: <HvacOutlinedIcon sx={{ height: '100%', width: '100%', color: 'rgb(44, 46, 66)' }} />,
    path: 'hvac',
    intents: [
      { name: 'projects', route: 'app/hvac/projects', icon: <ListIcon /> },
      {
        name: 'apps',
        route: 'inventory/appconfiguration/HVAC',
        icon: <WysiwygSharp />,
        restrictions: [PERMISSIONS_WRITE_ACCOUNT],
      },
    ],
  },
};

export const availableApps = (products: AccountAppRunConfiguration[]) => {
  const list: App[] = [];
  const mappedProductKeys = products?.map((item) => item.product.key) ?? [];

  Object.keys(AppRegister).map((key) => {
    const app = AppRegister[key as ProjectType];
    if (mappedProductKeys.includes(app.product)) {
      list.push(app);
    }
  });
  return list;
};
