import { Navigate, Route, Routes } from 'react-router-dom';
import { AllPagesLayout } from '../pages/AllPagesLayout';
import { WidgetRoutes } from './WidgetRoutes';
import { PlatformRoutes } from './PlatformRoutes';
import { AuthProvider } from '../contexts/Auth0Context';

// TODO: This are temporary redirects for the current installations. These should be removed

const wigetsRedirectsForTest = (
  <>
    <Route path="stiebel/index.html" element={<Navigate to="/widget/67110c3ad30a08ff959cccb6" replace />} />
    <Route path="tecalor/index.html" element={<Navigate to="/widget/67110c6bd30a08ff959cccb9" replace />} />
  </>
);

const wigetsRedirectsForProduction = (
  <>
    <Route path="stiebel/index.html" element={<Navigate to="/widget/67127f3e430ec35b20ada6c1" replace />} />
    <Route path="tecalor/index.html" element={<Navigate to="/widget/67127f3e430ec35b20ada6c2" replace />} />
  </>
);

const isOnTest = import.meta.env.MODE === 'test';
const isOnProduction = import.meta.env.MODE === 'production';

export const MainRouter = () => {
  return (
    <Routes>
      <Route path="widget/:applicationKey/*" element={<WidgetRoutes />} />
      {isOnTest && wigetsRedirectsForTest}
      {isOnProduction && wigetsRedirectsForProduction}
      <Route element={<AllPagesLayout />}>
        <Route
          path="/*"
          element={
            <AuthProvider>
              <PlatformRoutes />
            </AuthProvider>
          }
        />
      </Route>
    </Routes>
  );
};
