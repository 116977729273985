import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { translationEN, translationDE } from '@dbel/react-commons/components';
import localeEn from './languages/en.json';
import localeDe from './languages/de.json';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

const detectionOptions = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

const portalInstance = i18n.createInstance();

portalInstance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.DEV,
    resources,
    detection: detectionOptions,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

portalInstance.addResourceBundle('en', 'translation', localeEn, true, true);
portalInstance.addResourceBundle('de', 'translation', localeDe, true, true);

export default portalInstance;
