import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  Box,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Theme,
  Typography,
} from '@mui/material';
import { useGetMessagesQuery } from '@dbel/react-commons/api';
import { StatusMessage } from '@dbel/shared/types';
import { MapBoxOverlayContainer } from '@dbel/react-commons/components';
import { grey } from '@mui/material/colors';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackendConnectionStatus } from '../../contexts/BackendConnectionContext';
import { BackendConnectionIndicator } from './BackendConnectionIndicator';
import { LoadingIcon } from './LoadingIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query';

function normaliseProgressValue(value: number, max: number) {
  return ((value - 0) * 100) / (max - 0);
}

export interface LoadingIndicatorProps {
  projectId?: string;
}

export const LoadingIndicator = ({ projectId }: LoadingIndicatorProps) => {
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const backendConnectionStatus = useBackendConnectionStatus();

  const { data: messages } = useGetMessagesQuery(projectId ?? skipToken);

  const [opened, setOpened] = useState(false);

  const handleClickOpened = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const handleClose = (): void => {
    setOpened(false);
  };

  return (
    <>
      {backendConnectionStatus === 'CONNECTED' &&
        messages &&
        messages.length > 0 && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <MapBoxOverlayContainer
                sx={{
                  borderRadius: '20px',

                  height: 34,
                  width: 34,
                }}
              >
                <IconButton
                  size='small'
                  onClick={handleClickOpened}
                  ref={anchorRef}
                >
                  <Box>
                    <LoadingIcon />
                  </Box>
                </IconButton>
              </MapBoxOverlayContainer>
            </Box>
            {opened && (
              <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                  horizontal: 'center',
                  vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={opened}
                PaperProps={{
                  sx: { mt: 1 },
                }}
                sx={{ zIndex: (theme: Theme) => theme.zIndex.modal + 1 }}
              >
                <List dense sx={{ py: 0.5 }}>
                  <ListItem sx={{ px: 1 }}>
                    <ListItemIcon>
                      <DragHandleIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant='subtitle2'>
                        {t('common.loadingIndicator.running')}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {messages &&
                    messages.map((message: StatusMessage, index: number) => (
                      <ListItem sx={{ px: 1, py: 0.2 }} key={index}>
                        <ListItemIcon>
                          <SvgIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <Stack>
                            <Stack direction='row'>
                              <Typography variant='body2' sx={{ mr: 'auto' }}>
                                {t(
                                  `common.loadingIndicator.message.type.${message.taskType}`
                                )}
                              </Typography>
                              <Typography
                                variant='body2'
                                sx={{ ml: 2, color: grey[500] }}
                              >
                                (
                                {message.subTasks.succeeded +
                                  message.subTasks.failed}
                                /{message.subTasks.scheduled})
                              </Typography>
                            </Stack>
                            <LinearProgress
                              variant='determinate'
                              value={normaliseProgressValue(
                                message.subTasks.succeeded +
                                  message.subTasks.failed,
                                message.subTasks.scheduled
                              )}
                              sx={{ height: 2 }}
                            />
                          </Stack>
                        </ListItemText>
                      </ListItem>
                    ))}
                </List>
              </Popover>
            )}
          </>
        )}

      {(backendConnectionStatus !== 'CONNECTED' ||
        (backendConnectionStatus === 'CONNECTED' &&
          messages?.length === 0)) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <BackendConnectionIndicator />
        </Box>
      )}
    </>
  );
};
