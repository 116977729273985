import CircleIcon from '@mui/icons-material/Circle';
import { Theme, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MapBoxOverlayContainer } from '@dbel/react-commons/components';
import {
  BackendConnectionStatusType,
  useBackendConnectionStatus,
} from '../../contexts/BackendConnectionContext';

function getIndicatorColor(
  theme: Theme,
  backendConnectionStatus: BackendConnectionStatusType
): string {
  switch (backendConnectionStatus) {
    case 'CONNECTED':
      return theme.palette.success.main;
    case 'BACKEND_ERROR':
      return theme.palette.warning.main;
    default:
      return theme.palette.error.main;
  }
}

export const BackendConnectionIndicator = () => {
  const { t } = useTranslation();

  const backendConnectionStatus = useBackendConnectionStatus();

  return (
    <>
      <Tooltip
        placement='left'
        title={t(
          `common.backendConnectionIndicator.status.${backendConnectionStatus}`
        )}
      >
        <MapBoxOverlayContainer
          sx={{
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircleIcon
            sx={{
              height: '24px',
              width: '24px',
              margin: '5px',
              color: (theme: Theme) =>
                getIndicatorColor(theme, backendConnectionStatus),
            }}
          />
        </MapBoxOverlayContainer>
      </Tooltip>
    </>
  );
};
