import { Box, Typography } from '@mui/material';
export const ModuleNotFound = () => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    minHeight='100vh'
    width='100vw'
  >
    <Typography color='grey'>Module not found</Typography>
  </Box>
);
