import { webSocketManager } from '@dbel/react-commons/api';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

export type BackendConnectionStatusType = 'CONNECTED' | 'DISCONNECTED' | 'BACKEND_ERROR';

const BackendConnectionStatusContext = createContext<BackendConnectionStatusType>('DISCONNECTED');

export const useBackendConnectionStatus = () => useContext<BackendConnectionStatusType>(BackendConnectionStatusContext);

export interface BackendConnectionContextProps {
  projectId?: string;
}

export const BackendConnectionContextProvider = ({
  children,
  projectId,
}: PropsWithChildren<BackendConnectionContextProps>) => {
  const [backendConnectionStatus, setBackendConnectionStatus] = useState<BackendConnectionStatusType>('DISCONNECTED');

  useEffect(() => {
    if (projectId === undefined) return;
    const onConnected = () => {
      // if a backend error occurs, don't allow status change back to CONNECTED
      if (backendConnectionStatus !== 'BACKEND_ERROR') {
        setBackendConnectionStatus('CONNECTED');
      }
    };

    const onDisconnected = () => {
      // if a backend error occurs, don't allow status change back to DISCONNECTED
      if (backendConnectionStatus !== 'BACKEND_ERROR') {
        setBackendConnectionStatus('DISCONNECTED');
      }
    };

    const onBackendError = () => {
      setBackendConnectionStatus('BACKEND_ERROR');
    };

    webSocketManager.addConnectionStatusListener(projectId, {
      connected: onConnected,
      disconnected: onDisconnected,
      backendError: onBackendError,
    });

    return () => {
      webSocketManager.removeConnectionStatusListener(projectId);
    };
  }, [backendConnectionStatus, projectId]);

  return (
    <>
      <BackendConnectionStatusContext.Provider value={backendConnectionStatus}>
        {children}
      </BackendConnectionStatusContext.Provider>
    </>
  );
};
