import { Route, Routes, useParams } from 'react-router-dom';
import { HVACWidget } from './loadables';
import { getDBelApiCredentials, useGetConfigForApplicationKeyQuery } from '@dbel/react-commons/api';
import { AllWidgetsLayout } from '../pages/AllWidgetsLayout';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import portalInstance from '../locales/i18n';
import { SplashScreen } from '@dbel/react-commons/components';

const authorizationType = 'KEY';

// TODO: at the moment we only have one key for stiebel
const apiKey = import.meta.env.VITE_DBEL_API_KEY;

export const WidgetRoutes = () => {
  const apiCredential = getDBelApiCredentials();
  const { applicationKey } = useParams();
  const { data: appConfig, isLoading } = useGetConfigForApplicationKeyQuery(applicationKey ?? skipToken);

  return (
    <Routes>
      <Route element={<AllWidgetsLayout />}>
        {appConfig && appConfig.product.key === 'HVAC' && (
          <Route
            path="*"
            element={
              isLoading ? (
                <SplashScreen />
              ) : (
                <HVACWidget
                  apiCredentials={{
                    wsEndpoint: apiCredential.wsEndpoint,
                    apiEndpoint: apiCredential.apiEndpoint,
                    accountKey: appConfig.account.key,
                    authorizationType,
                    apiKey,
                  }}
                  language={portalInstance.language}
                  appConfig={appConfig}
                />
              )
            }
          />
        )}
      </Route>
      <Route path="*" element={<></>} />
    </Routes>
  );
};
