import { Loadable } from '@dbel/react-commons/components';
import { lazy } from 'react';
import { ModuleNotFound } from '../components/ModuleNotFound';

const height = { height: 'calc(100vh - 96px)' }; // to center the logo during loading

// Module federation Remotes
export const HVAC = Loadable(
  lazy(() =>
    import('hvac/HVAC')
      .then((module) => ({
        default: module.default.HVAC,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  ),
  height
);

export const HVACWidget = Loadable(
  lazy(() =>
    import('hvac/HVAC')
      .then((module) => ({
        default: module.default.HVAC,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  )
);

export const Architect = Loadable(
  lazy(() =>
    import('architect/Architect')
      .then((module) => ({
        default: module.default.Architect,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  ),
  height
);

export const RoomAcousticCalculator = Loadable(
  lazy(() =>
    import('architect/RoomAcousticCalculator')
      .then((module) => ({
        default: module.default.RoomAcousticCalculator,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  ),
  height
);

export const DBelOrganisations = Loadable(
  lazy(() =>
    import('dBelOrganisations/App')
      .then((module) => ({
        default: module.default.App,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  ),
  height
);

export const DBelInventory = Loadable(
  lazy(() =>
    import('dBelInventory/App')
      .then((module) => ({
        default: module.default.App,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  ),
  height
);

// error pages in shared
export const AuthorizationRequired = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.AuthorizationRequired,
    }))
  )
);
export const ServerError = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.ServerError,
    }))
  )
);
export const PaymentRequired = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.PaymentRequired,
    }))
  )
);
export const Forbidden = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.Forbidden,
    }))
  )
);
export const NotFound = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.NotFound,
    }))
  )
);
