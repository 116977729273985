import { createTheme, ThemeOptions } from '@mui/material';
// needed to have type checking for lab components,
// see https://mui.com/material-ui/about-the-lab/#typescript
import type { LabComponents } from '@mui/lab/themeAugmentation';

// needed to force IDEs not to remove the import type statement from above
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let dummy: LabComponents;

const baseThemeOptions: ThemeOptions = {
  shape: {
    borderRadius: 16,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  },
  zIndex: {
    modal: 1800,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // /* Disable up/down arrows for TextField type="number" */

        // /* Chrome, Safari, Edge, Opera */
        'input::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        'input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },

        // /* Firefox */
        "input[type='number']": {
          MozAppearance: 'textfield',
        },

        html: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        square: true,
        elevation: 0,
        TransitionProps: { unmountOnExit: true },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAccordionDetails: {},
    MuiList: {
      styleOverrides: {
        dense: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        dense: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: '5px',
          minWidth: 0,
          color: 'currentColor',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        dense: {
          padding: 0,
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 0,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { orientation: 'vertical ' },
              style: { height: 1, margin: 0 },
            },
            {
              props: { orientation: 'horizontal ' },
              style: { height: 1, margin: 0, width: '100%' },
            },
          ],
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        sizeSmall: {
          height: '34px',
          width: '36px',
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        disableShrink: true,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        sizeSmall: {
          width: '37px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        sizeMedium: {
          paddingTop: 10,
          paddingBottom: 10,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.71,
          minWidth: 'auto',
          paddingLeft: 0,
          paddingRight: 0,
          textTransform: 'none',
          '& + &': {
            marginLeft: 24,
          },
        },
      },
    },
    MuiInputAdornment: {
      defaultProps: {
        disableTypography: true,
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { size: 'large' },
          style: {
            '.MuiInputBase-root': {
              fontSize: '16px',
              fontWeight: 700,
            },
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        text: {},
        root: {
          textTransform: 'none',
          '&.MuiLoadingButton-root': {
            minWidth: 'auto',
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'inherit',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          borderRadius: 6,
        },
        root: {
          '&': {
            borderRadius: 6,
          },
          '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: '1px solid transparent',
          },
          '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #2196F3',
          },
          '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            padding: 5,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'medium',
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
};

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    primaryInverted: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

const baseTheme = createTheme(baseThemeOptions);

export default baseTheme;
