import { useEffect } from 'react';

const CONSENT_URL =
  'https://cloud.ccm19.de/app.js?apiKey=e704f69a794e414948a9075516e8651225d30621752e635b&amp;domain=651417d48fd5d7ed620ae402';

export const useConsent = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = CONSENT_URL;
    script.async = true;
    script.referrerPolicy = 'origin';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
