import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import DBELHeader from './components/DBELHeader';

const Main = styled('main')(({ theme }) =>
  theme.unstable_sx({
    height: '100vh',
  })
);

const Content = styled('div')(({ theme }) =>
  theme.unstable_sx({
    margin: 'auto',
  })
);

export const AllSignUpPagesLayout = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pages.signUp.pageTitle')}</title>
      </Helmet>
      <Main>
        <DBELHeader />
        <Content>
          <Outlet />
        </Content>
      </Main>
    </>
  );
};
