import { keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';

const rippleAnimation = keyframes`
    0% {
      top: 10px;
      left: 10px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 20px;
      height: 20px;
      opacity: 0;
    }
`;

interface RippleProps {
  animationDelay?: number;
}

const Ripple = styled('div', {
  shouldForwardProp: (prop) => (prop as keyof RippleProps) !== 'animationDelay',
})<RippleProps>(({ animationDelay, theme }) => [
  theme.unstable_sx({
    position: 'absolute',
    borderWidth: '2px',
    borderColor: 'primary.main',
    borderStyle: 'solid',
    opacity: 1,
    borderRadius: '50%',
    animationDuration: '1s',
    animationDirection: 'normal',
    animationFillMode: 'none',
    animationDelay: `${animationDelay}s`,
    animationTimingFunction: 'cubic-bezier(0, 0.2, 0.8, 1)',
    animationIterationCount: 'infinite',
  }),
  {
    animationName: rippleAnimation,
  },
]);

const Container = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'inline-block',
    position: 'relative',
    width: '24px',
    height: '24px',
    boxSizing: 'initial',
  })
);

export const LoadingIcon = () => (
  <>
    <Container>
      <Ripple animationDelay={-0.5} />
      <Ripple animationDelay={-0.8} />
      <Ripple />
    </Container>
  </>
);
