import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { MouseEvent, ReactNode, useCallback } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

export interface SubMenuEntryProps {
  title: string;
  icon?: ReactNode;
  navTo: string;
}

export const SubMenuEntry = ({ title, icon, navTo }: SubMenuEntryProps) => {
  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (navTo.includes('mailto:')) {
        window.location.href = navTo;
        event.preventDefault();
      }
    },
    [navTo]
  );

  return (
    <>
      <ListItemButton
        component={RouterLink}
        to={navTo}
        onClick={handleOnClick}
        sx={{
          '&.active': {
            color: 'primary.main',
          },
        }}
      >
        {icon && (
          <ListItemIcon sx={{ color: '000000', opacity: 0.56 }}>
            {icon}
          </ListItemIcon>
        )}

        <ListItemText sx={{ color: '000000', opacity: 0.87 }}>
          {title}
        </ListItemText>
      </ListItemButton>
    </>
  );
};
