import { DBelLogoSvg } from '@dbel/react-commons/assets';
import { Box } from '@mui/material';

const DBELHeader = () => (
  <>
    <Box
      sx={{
        height: '60px',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        background: '#F0F0EB',
      }}
    >
      <Box sx={{ width: '1024px', m: 'auto' }}>
        <DBelLogoSvg sx={{ fontSize: 60, ml: 2 }} />
      </Box>
    </Box>
  </>
);

export default DBELHeader;
