import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store.ts';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import lightTheme from './theme/lightTheme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { setDBelEndpoints } from '@dbel/react-commons/api';
import { apiConfig, sentryConfig, websocketConfig } from './config.ts';
import portalInstance from './locales/i18n.ts';
import { I18nextProvider } from 'react-i18next';
import { moduleMetadataIntegration, browserTracingIntegration, init } from '@sentry/react';
import { MainRouter } from './routes/MainRouter.tsx';
import { BITBUCKET_COMMIT } from './version.ts';

setDBelEndpoints({
  wsEndpoint: websocketConfig.endpointBaseUrl,
  apiEndpoint: apiConfig.endpointBaseUrl,
});

// init sentry
init({
  autoSessionTracking: true,
  dsn: sentryConfig.baseUrl,
  dist: BITBUCKET_COMMIT,
  //debug: false,
  //debug: !import.meta.env.PROD,
  integrations: [moduleMetadataIntegration(), browserTracingIntegration()],
  environment: sentryConfig.env,
  tracesSampleRate: sentryConfig.sampleRate,
  enabled: BITBUCKET_COMMIT !== 'local',
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <HelmetProvider>
    <I18nextProvider i18n={portalInstance}>
      <Provider store={store}>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <BrowserRouter>
            <Helmet
              htmlAttributes={{ lang: portalInstance.language, dir: portalInstance.dir() }}
              defaultTitle="dBEL"
              titleTemplate="dBEL - %s"
            />
            <MainRouter />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  </HelmetProvider>
);
